const routes = [
    {
        label: 'Contact',
        path: '/contact',
    },
    {
        label: 'Book Now | 预约',
        path: '/appointment',
    },
];

export default routes;
